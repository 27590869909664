import React, { useState } from "react";
import { Button } from "../common/Button";
import { Heading } from "../common/Heading";
import wallet from "../../assets/images/wallet.svg";
import { BackButton } from "../common/BackButton";
import { HeaderLogoLeft } from "../common/HeaderLogoLeft";
import {
  assignLimit,
  checkout,
  getUser,
  tokenDecode,
  getCibil,
} from "../../services/ApiServices";
import { toast } from "react-toastify";
import NotEnoughBalance from "../common/modal/NotEnoughBalance";
import CommanModal from "../common/modal/SuccessModal";
import { useEffect } from "react";
import { Loader } from "../common/loader/Loader";
import { useHistory } from "react-router-dom";

export const AssignLimit = (props) => {
  let history = useHistory();
  const [show, setShow] = useState(false);
  const [showNotEnough, setShowNotEnough] = useState(false);
  const [limitData, setLimitData] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState("");

  useEffect(() => {
    getUserDetails();
    getDeatils();
  }, []);

  //get checkout details
  const getDeatils = () => {
    let req = {
      jwt_token: localStorage.getItem("jwt_token"),
    };
    tokenDecode(req)
      .then((res) => {
        if (res.status === 200) {
          console.log(res?.data);
          setCheckoutDetails(res?.data?.data);
        }
      })
      .catch((err) => {
        if(err?.response?.status===401){
          localStorage.clear();
          history.push('/')
        }
        toast.error(err?.response?.data?.error);
      });
  };

  //get user details
  const getUserDetails = () => {
    setIsLoader(true);
    getUser()
      .then((res) => {
        if (res.status === 200) {
          setUserDetails(res?.data?.data);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        if(err?.response?.status===401){
          localStorage.clear();
          history.push('/')
        }
        toast.error(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    creditLimit();
  }, []);

  const creditLimit = () => {
    assignLimit()
      .then((res) => {
        if (res.status === 200) {
          setLimitData(res?.data?.data);
              getUserCibil(res?.data?.data?.total_balance);

        }
      })
      .catch((err) => {
        if(err?.response?.status===401){
          localStorage.clear();
          history.push('/')
        }
        toast.error(err?.response?.data?.error);
      });
  };

  const getUserCibil = (data) => {
    if (data == undefined) {
      getCibil()
        .then((res) => {
          if (res.status === 200) {
            let redirectUrl = res.data.url.split("&redirect")?.[0];
            window.location.href=`${redirectUrl}&redirect=${window.location.href}`
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
          if(err?.response?.status===401){
            localStorage.clear();
            history.push('/')
          }
        });
    }
  };

  const handleSubmit = () => {
    let req = {
      amount: checkoutDetails?.orders[0]?.amount,
      merchant_id: checkoutDetails?.merchant_id,
      txn_id: checkoutDetails?.orders[0]?.txn_id,
    };
    setLoader(true);
    checkout(req)
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          setLoader(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
            window.location.href = `${checkoutDetails?.redirect_url}`;
          }, 3000);
        }
      })
      .catch((err) => {
        if(err?.response?.status===401){
          localStorage.clear();
          history.push('/')
        }else{
        localStorage.clear();
        setLoader(false);
        setTimeout(() => {
          setShowNotEnough(true);
          // window.location.href = `${checkoutDetails?.redirect_url}`;
        }, 3000);
      }
        toast.error(err?.response?.data?.error);
      });
  };

  return (
    <div className="main text-center">
      {isLoader && <Loader />}
      {console.log("limitData1", typeof limitData == "object")}
      <NotEnoughBalance
        show={showNotEnough}
        onHide={() => setShowNotEnough(false)}
      />
      <CommanModal
        show={show}
        onHide={() => setShow(false)}
        message="Succesfull Checkout"
      />
      <div className="clearfix">
        <HeaderLogoLeft />
        <Heading heading="Assigned Limit" />
      </div>
      <div className="clearfix">
        {/* <BackButton path="/assign-limit" /> */}
      </div>
      <div className="bgblue">
        {<img src={wallet} alt="img2" className="m-t-10" />}
        <h5 className="textclr">
          Hi {userDetails?.customusermodel?.first_name}!
        </h5>
        <p className="textclr">
          Your available limit is ₹
          {limitData?.total_balance === undefined
            ? 0
            : limitData?.total_balance}{" "}
        </p>
        {console.log("limitData", typeof limitData)}
        <h2 className="textclr m-t-50">Your checkout amount</h2>
        <p className="credit-limit">
          ₹{checkoutDetails?.orders && checkoutDetails?.orders[0].amount}
        </p>
      </div>
      <div className="" onClick={handleSubmit}>
        <Button
          buttonName="Checkout Amount"
          loader={loader}
          isDisabled={
            false
            // limitData?.total_balance <= checkoutDetails?.amount ? true : false
          }
        />
      </div>
    </div>
  );
};
