/**
 * @About
 * This file mange environment of the project
 */

//dev
export const devSetting = {
  api: {
    url: "https://dev.api.salt.paymeindia.in/api/",
    // AUTH: "",
    mode: "cors",
  }
};

//stag
export const stagSetting = {
  api: {
    url: "",
    AUTH: "",
    mode: "cors",
  },
};

//prod
export const prodSetting = {
  api: {
    url: "",
  },
};

//local
export const localhostSetting = {
  api: {
    url: process.env.REACT_APP_LOCAL_API_URL,
    mode: "cors",
  },
};
