import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common/Button";
import { Heading } from "../common/Heading";
import smartphone from "../../assets/images/smartphone.png";
import { Header } from "../common/Header";
import InlineError from "../../helper/Error/InlineError";
import { phoneVerify, sendOtp } from "../../services/ApiServices";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { mobileNoRegex } from "../../helper/validation/Validatiion";
import {Link} from 'react-router-dom'

export const MobileNumber = () => {
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [isDisabled, SetIsDisabled] = useState(true);
  const [loader, setLoader] = useState(false);

  const onChangeNumber = (e) => {
    if (e.target.value === "" || mobileNoRegex.test(e.target.value)) {
      setMobileNumber(e.target.value);
      setMobileNumberError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mobileNumber.length !== 10) {
      setMobileNumberError("Please enter a valid phone number");
    } else {
      let req = {
        phone_number: mobileNumber,
      };
      setLoader(true);
      phoneVerify(req)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.phone_number_verified === true) {
              sendOtp(req).then((res) => {
                if (res.status === 200) {
                  setLoader(false);
                  history.push({
                    pathname: "/verify-otp",
                    search: `?mobileNumber=${mobileNumber}&phone_number_verified=${true} `,
                  });
                }
              });
            } else {
              history.push({
                pathname: "/verify-otp",
                search: `?mobileNumber=${mobileNumber}&phone_number_verified=${false}`,
                // state: { detail: response.data }
              });
            }
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err?.response?.data?.error);
        });
    }
  };

  useEffect(() => {
    if (window.location.search !== "") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.setu_jwt_token) {
        history.push({
          pathname: "/setu-web-view",
          search: `?setu_jwt_token=${params.setu_jwt_token}&auth_token=${params.auth_token} `,
        });
      } else {
        console.log('setuuuu', params);
        setMobileNumber(params.mobileNumber);
        localStorage.setItem("jwt_token", params?.jwt_token);
        console.log("params", params);
      }
    }
    localStorage.removeItem('token')
  }, []);

  const agreeTerm = (term) => {
    SetIsDisabled(!term);
    // window.open('https://openscecurityurl.s3.ap-south-1.amazonaws.com/SaltAppDocs/saltt%26c.pdf')
  };


console.log('test>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
  return (
    <div className="main text-center">
      <Header />
      <div className="m-t-50">
        <Heading heading="Enter Mobile Number" />
      </div>
      <div className="heading">
        <img src={smartphone} alt="img2" />
      </div>
      <form onSubmit={handleSubmit} className="text-left form-allign">
        <label className="textclr m-t-80">Mobile Number </label>
        <div className="form-allign">
          <input
            type="text"
            className="input-text m-t-10"
            placeholder="Mobile Number"
            value={mobileNumber}
            name="mobileNumber"
            maxlength="10"
            onChange={onChangeNumber}
          />
          <InlineError errorMessage={mobileNumberError} />
        </div>
        <div className="checkbox">
          <p className="textclr m-t-20">
            <label className="check-box" for="checkbox cursor-ptr">
              <input
                type="checkbox"
                className="m-r-10 input-checkbox"
                name="checkbox"
                id="checkbox"
                onClick={() => agreeTerm(isDisabled)}
              />
              I agree to the SALT's <a href={"https://openscecurityurl.s3.ap-south-1.amazonaws.com/SaltAppDocs/saltt%26c.pdf"}>T&C</a> and Privacy policy
              
            </label>
          </p>
        </div>
        <Button
          buttonName="Proceed"
          type="Submit"
          isDisabled={isDisabled}
          loader={loader}
          onClick={handleSubmit}
        />
      </form>
    </div>
  );
};
