import React from "react";
import { useHistory } from "react-router-dom";
import BackArrow  from "../../assets/images/BackArrow.svg";

export const BackButton = (props) => {
    const history = useHistory()
  return (
    <div className="float-left p-l-15 res-padding" onClick={()=>history.push(`${props.path}`)}>
     <img src={BackArrow} alt="Back button"/>
    </div>
  );
};
