import React from "react";
import { Modal } from "react-bootstrap";

import notEnoughBlc  from "../../../assets/images/notEnoughBlc.png";

function NotEnoughBalance(props) { 
  return (
    <Modal {...props} centered className="modal-all text-center">
      <Modal.Body className="p-0">
        <div className="custom-mdl" >
          {/* <div id="react-logo" style={{ width: 305, height: 275 }} /> */}
          <img src={notEnoughBlc} alt="Not" />

          <h4>Not Enough Balance</h4>
          <p>Your Checkout amount is greater then current limit</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default NotEnoughBalance;
