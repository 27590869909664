import React from "react";
import { Modal } from "react-bootstrap";
import success from "../../../assets/animation/success.gif";

function CommanModal(props) {

  return (
    <Modal {...props} centered className="modal-all text-center">
      <Modal.Body className="p-0">
        <div className="custom-mdl">
        <img src={success} style={{ width: 295, height: 275, margin: "auto" }} alt='gif'/>
          <h4>Congratulations!!</h4>
          <p>{props?.message}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default CommanModal;
