import React from "react";
import { MySpinner } from "../common/loader/Loader";

export const Button = (props) => {
  return (
    <>
      <button className="btn textclr cursor-ptr res-margin" disabled={props.isDisabled}>
        {props.buttonName} {props.loader && <MySpinner />}
      </button>
    </>
  );
};
