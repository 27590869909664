import React from "react";
import { useState } from "react";
import { Button } from "../common/Button";
import { Heading } from "../common/Heading";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { Header } from "../common/Header";
import { Timer } from "../../helper/timer/Timer";
import InlineError from "../../helper/Error/InlineError";
import {
  otpVerify,
  createOnboardUser,
  phoneVerify,
  sendOtp,
  getDocs
} from "../../services/ApiServices";
import CommanModal from "../common/modal/SuccessModal";
import { useEffect } from "react";
import { toast } from "react-toastify";
import BackArrow from "../../assets/images/BackArrow.svg";

export const Otp = () => {
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [verifyPhone, setVerifyPhone] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [second, setSecond] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const docsStatus = () => {
    setIsLoader(true);
    getDocs()
      .then((res) => {
        if (res.status === 200) {
          setIsLoader(false);
          if (res?.data?.data[0]?.kyc_verified === "VERIFIED") {
            history.push("/assign-limit");
          }else{
            setTimeout(() => {
              // history.push("/complete-your-kyc");
              window.location.href = "/complete-your-kyc";
              // window.location.href = `https://ekyc-staging.paymeindia.in/?token=${res.data.data.token}&redirectFrom=saltWebView`
            }, 3000);
          }
        }
      })
      .catch((err) => {
        setIsLoader(false);
        toast.error(err?.response?.data?.error);
      });
  };

  const handleChange = (e) => {
    setOtp(e);
    setOtpError("");
  };

  const handleSubmit = () => {
    if (otp.length !== 4) {
      setOtpError("Please enter OTP");
    } else {
      if (verifyPhone === "false") {
        CreateUser();
      } else {
        verify();
      }
    }
  };

  const verify = () => {
    let req = {
      phone_number: mobileNumber,
      otp,
      forget_password: true,
    };
    setLoader(true);
    otpVerify(req)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.otp_verified) {
            setLoader(false);
            localStorage.setItem("token", res.data.data.token);
            setShow(true);
            setTimeout(() => {
              // history.push("/complete-your-kyc");
              window.location.href = "/complete-your-kyc";
              // window.location.href = `https://ekyc-staging.paymeindia.in/?token=${res.data.data.token}&redirectFrom=saltWebView`
            }, 3000);
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response?.data?.error === "Otp is Expired !!") {
          setOtp("");
        }
        setIsDisabled(true);
        setTimeout(() => {
          setIsDisabled(false);
        }, 5000);
        toast.error(err?.response?.data?.error);
      });
  };

  // createOnboardUser
  const CreateUser = () => {
    let req = {
      phone_number: mobileNumber,
      otp,
    };
    setLoader(true);
    createOnboardUser(req)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (res.data?.otp_verified) {
          } else {
            setShow(true);
            setTimeout(() => {
              history.push({
                pathname: "/sign-in",
                search: `?mobileNumber=${mobileNumber}`,
              });
            }, 3000);
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response?.data?.error === "Otp is Expired !!") {
          setOtp("");
        }
        setIsDisabled(true);
        setTimeout(() => {
          setIsDisabled(false);
        }, 5000);
        toast.error(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setMobileNumber(params.mobileNumber);
    setVerifyPhone(params.phone_number_verified);

    if (localStorage.getItem("token")) {
      history.goBack();
    }
  }, []);

  const resedOtp = () => {
    setOtp("");
    setSecond(180);
    let req = {
      phone_number: mobileNumber,
    };
    setLoader(true);
    phoneVerify(req)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.phone_number_verified === true) {
            sendOtp(req).then((res) => {
              if (res.status === 200) {
                history.push({
                  pathname: "/verify-otp",
                  search: `?mobileNumber=${mobileNumber}&phone_number_verified=${true} `,
                });
                setLoader(false);
              }
            });
          } else {
            history.push({
              pathname: "/verify-otp",
              search: `?mobileNumber=${mobileNumber}&phone_number_verified=${false}`,
              // state: { detail: response.data }
            });
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.error);
      });
  };

  return (
    <div className="main text-center">
      <CommanModal
        show={show}
        onHide={() => setShow(false)}
        message="Account is activated to use"
      />
      <div
        className="float-left p-l-45 "
        onClick={() =>
          history.push({
            pathname: "/",
            search: `?mobileNumber=${mobileNumber}`,
          })
        }
      >
        <img src={BackArrow} alt="Back button" />
      </div>
      <Header />
      <div className="m-t-40">
        <Heading heading="Mobile Verification" />
      </div>

      <div className="textclr m-t-10">Enter your OTP code here</div>
      <div className="form-allign m-t-60">
        <OtpInput
          isInputNum={true}
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span>-</span>}
          className="form-allign circle-otp"
        />
        <InlineError errorMessage={otpError} />
      </div>

      <div>
        <Timer seconds={second} resedOtp={resedOtp} />
      </div>

      <div onClick={handleSubmit} className="modal-center">
        <Button
          buttonName="Verify"
          type="Submit"
          loader={loader}
          isDisabled={isDisabled}
        />
      </div>
      {console.log(isDisabled,'isDisabled')}
    </div>
  );
};
