import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Heading } from "../common/Heading";
import mSizeLogo from "../../assets/images/mSizeLogo.svg";
import google from "../../assets/images/google.svg";
import { userRgister } from "../../services/ApiServices";
import { toast } from "react-toastify";
import { useEffect } from "react";
import GoogleLogin from "react-google-login";

export const SignInWithGmail = () => {
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState("");

  const responseGoogle = (responseGoogle) => {
    let req = {
      access_token: responseGoogle.tokenId,
      phone_number: mobileNumber,
    };
    userRgister(req)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res?.data?.data?.token);
          history.push("/complete-your-kyc");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };
  const responseFail = (err) => {
    console.log(err);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setMobileNumber(params.mobileNumber);

    if (localStorage.getItem("token")) {
      history.goBack();
    }
  }, []);
  return (
    <div className="main text-center">
      <div className="m-t-50">
        <Heading heading="Get started with" />
      </div>
      <img src={mSizeLogo} alt="logo" className="m-t-40" />
      <p className="textclr m-t-80"></p>
      <GoogleLogin
        clientId="435990090197-cjdhhppfhvq8e9n0cullbtco1u22mf1g.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseFail}
        cookiePolicy={"single_host_origin"}
        className="signin-btn textclr"
      >
        <div className="">
          <img src={google} className="google-logo" /> Continue with Google
        </div>
      </GoogleLogin>
    </div>
  );
};
