import React, { useState } from "react";
import { Button } from "../common/Button";
import { Heading } from "../common/Heading";
import { useHistory } from "react-router-dom";
import { BackButton } from "../common/BackButton";
import { HeaderLogoLeft } from "../common/HeaderLogoLeft";
import { useEffect } from "react";
import { getKyc, getDocs } from "../../services/ApiServices";
import { toast } from "react-toastify";
import kycAnimation from "../../assets/animation/kycAnimation.gif";
import { Loader } from "../common/loader/Loader";

export const CompleteKyc = () => {
  let history = useHistory();
  const [url, setUrl] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const docsStatus = () => {
    setIsLoader(true);
    getDocs()
      .then((res) => {
        if (res.status === 200) {
          setIsLoader(false);
          if (res?.data?.data[0]?.kyc_verified === "VERIFIED") {
            history.push("/assign-limit");
          }
        }
      })
      .catch((err) => {
        setIsLoader(false);
        toast.error(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    docsStatus();
    getKycLink();
  }, []);

  console.log("loaction", window.location.href);
  const getKycLink = () => {
    getKyc()
      .then((res) => {
        if (res.status === 200) {
          setUrl(res.data.url);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error);
      });
  };

  const redirectEkyc = () => {
    let redirect = url.split("&web_ekyc_redirect")?.[0];
    window.location.href=`${redirect}&web_ekyc_redirect=${window.location.href}`
  };

  return (
    <div className="main text-center">
      {isLoader && <Loader />}
      <div className="clearfix">
        <HeaderLogoLeft />
        <Heading heading="Complete Your Kyc" />
      </div>
      <div className="clearfix ">{/* <BackButton path="verify-otp" /> */}</div>

      <div className="clearfix">
        <img src={kycAnimation} alt="img2" className="kyc-animation-img " />
      </div>
      <p className="text-wth">
        You will be redirected to web KYC website to verify your all the
        verification needed!
      </p>
      <div onClick={redirectEkyc}>
        <Button buttonName="Proceed Now" />
      </div>
    </div>
  );
};
