import React, { useState } from "react";
import { Button } from "../common/Button";
import { Heading } from "../common/Heading";
import wallet from "../../assets/images/wallet.svg";
import { BackButton } from "../common/BackButton";
import { HeaderLogoLeft } from "../common/HeaderLogoLeft";
import {
  assignLimit,
  checkout,
  getUser,
  tokenDecode,
  getCibil,
  setuTokenDecode,
  setuTransactionUpdate,
} from "../../services/ApiServices";
import { toast } from "react-toastify";
import NotEnoughBalance from "../common/modal/NotEnoughBalance";
import CommanModal from "../common/modal/SuccessModal";
import { useEffect } from "react";
import { Loader } from "../common/loader/Loader";

export const SetWebView = (props) => {
  const [show, setShow] = useState(false);
  const [showNotEnough, setShowNotEnough] = useState(false);
  const [limitData, setLimitData] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState("");

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    localStorage.setItem('token', params.auth_token)
    getcheckoutDetails(params.setu_jwt_token);
    setTimeout(() => {
      creditLimit();
    }, 1000);
  }, [localStorage.getItem('token')]);

  //get user details
  const getcheckoutDetails = (setu_jwt_token) => {
    setIsLoader(true);
    let res = {
      setu_jwt_token: setu_jwt_token
    }
    setuTokenDecode(res)
      .then((res) => {
        if (res.status === 200) {
          setUserDetails(res?.data?.data);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        toast.error(err?.response?.data?.error);
      });
  };

  const creditLimit = () => {
    setLoader(true);
    assignLimit()
      .then((res) => {
        if (res.status === 200) {
          console.log('settetete', res);
          setLimitData(res?.data?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error);
        setLoader(false);

      });
  };

  const handleSubmit = () => {
    let req = {
      amount: userDetails?.amount,
      merchant_id: userDetails?.merchant_id,
      order_id: userDetails?.orderId,
      txn_id: userDetails?.transactionId
    };
    setLoader(true);
    setuTransactionUpdate(req)
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          setLoader(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
            window.location.href = `${userDetails?.postPaymentUrl}`;
          }, 3000);
        }
      })
      .catch((err) => {
        localStorage.clear();
        setLoader(false);
        setTimeout(() => {
          setShowNotEnough(true);
          window.location.href = `${userDetails?.postPaymentUrl}`;
        }, 3000);

        // toast.error(err?.response?.data?.error);
      });
  };

  return (
    <div className="main text-center">
      {isLoader && <Loader />}
      {console.log("limitData1", typeof limitData == "object")}
      <NotEnoughBalance
        show={showNotEnough}
        onHide={() => setShowNotEnough(false)}
      />
      <CommanModal
        show={show}
        onHide={() => setShow(false)}
        message="Succesfull Checkout"
      />
      <div className="clearfix">
        <HeaderLogoLeft />
        {/* <Heading heading="Assigned Limit" /> */}
      </div>
      <div className="clearfix">
        {/* <BackButton path="/agree-term-condition" /> */}
      </div>
      <div className="bgblue">
        {<img src={wallet} alt="img2" className="m-t-10" />}
        <h5 className="textclr">
          Hi {userDetails?.name}!
        </h5>
        <p className="textclr">
          Your available limit is {limitData.total_balance}
        </p>
        {console.log("limitData", typeof limitData)}
        <h2 className="textclr m-t-50">Your checkout amount</h2>
        <p className="credit-limit">
          ₹{userDetails?.amount}
        </p>
      </div>
      <div className="" onClick={handleSubmit}>
        <Button
          buttonName="Checkout Amount"
          loader={loader}
          isDisabled={
            false
            // limitData?.total_balance <= checkoutDetails?.amount ? true : false
          }
        />
      </div>
    </div>
  );
};
