import React from 'react';
import { Spinner } from "react-bootstrap"


//Whole page loader
export const Loader = (props) => {
    return (
        <div className="loader_wrapper"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
    )
}

export const MySpinner = (props) => <Spinner size="sm" animation="border" className="sipner-clr" />