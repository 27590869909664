import React from "react";
import { Button } from "../common/Button";
import { Heading } from "../common/Heading";
import Termcondition from "../../assets/images/Termcondition.svg";
import { useHistory } from "react-router-dom";
import { HeaderLogoLeft } from "../common/HeaderLogoLeft";

export const AgreeTermAndCondition = () => {
  let history = useHistory();
  const handleSubmit = (e) => {
    history.push("/assign-limit");
  };

  return (
    <div className="main text-center">
      <div className="clearfix">
        <HeaderLogoLeft />
        <Heading heading="Agree T&C" />
      </div>
      <div className="clearfix">
        {/* <BackButton path="complete-your-kyc" /> */}
      </div>

      <div className="clearfix" style={{ marginTop: -20 }}>
        <img src={Termcondition} alt="img2" className='res-agree-img' />
      </div>
      <div className="m-t-40 clearfix" onClick={handleSubmit}>
        <Button buttonName="Agree and Continue" />
      </div>
    </div>
  );
};
