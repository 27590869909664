import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common/Button";
import { Heading } from "../common/Heading";
import saltlogo from "../../assets/images/saltlogo.svg";
import { BackButton } from "../common/BackButton";

export const AddBankDetails = () => {
  const history = useHistory();

  const handleChange = (e) => {
    // setMobileNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push("/verify-otp");
  };

  return (
    
    <div className="main">
        <BackButton  path='/assign-limit'/>
      <div className="logo">
        <img src={saltlogo} alt="img" />
      </div>
      <div className="">
        <Heading heading="Add Bank details" />
      </div>

      <div className=""></div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Account holder's name</label>
              <input
                type="text"
                placeholder="Enter full name"
                className="form-control"
                maxLength={50}
                name="fullName"
                // value={fullName}

                onChange={handleChange}
              />
              {/* {state.nameError && (
                <InlineError errorMessage={state.nameError} />
              )} */}
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Confirm Account Number</label>
              <input
                type="text"
                placeholder="Enter your last name"
                className="form-control"
                maxLength={50}
                name="confirmAccountNumber"
                // value={confirmAccountNumber}
                onChange={handleChange}
              />
              {/* {state.lastNameError && (
                <InlineError errorMessage={state.lastNameError} />
              )} */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Select Bank</label>
              <select className="form-control" onChange={handleChange}>
                <option value="" hidden>
                  Select Bank
                </option>
                <option value="">sbi</option>
              </select>
              {/* {state.nameError && (
                <InlineError errorMessage={state.nameError} />
              )} */}
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Bank IFSC Code</label>
              <input
                type="number"
                placeholder="Enter IFSC Code"
                className="form-control"
                maxLength={50}
                name="ifscCode"
                // value={ifscCode}
                onChange={handleChange}
              />
              {/* {state.lastNameError && (
                <InlineError errorMessage={state.lastNameError} />
              )} */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Account Type</label>
              <select className="form-control" onChange={handleChange}>
                <option value="" hidden>
                  Select Bank
                </option>
                <option value="">Saving</option>
              </select>
              {/* {state.nameError && (
                <InlineError errorMessage={state.nameError} />
              )} */}
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Bank Branch Name</label>
              <input
                type="text"
                placeholder="Enter Branch Name"
                className="form-control"
                maxLength={50}
                name="bankBranchName"
                // value={bankBranchName}
                onChange={handleChange}
              />
              {/* {state.lastNameError && (
                <InlineError errorMessage={state.lastNameError} />
              )} */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Account Number</label>
              <input
                type="text"
                placeholder="Enter full name"
                className="form-control"
                maxLength={50}
                name="accountNumber"
                // value={accountNumber}
                onChange={handleChange}
              />
              {/* {state.nameError && (
                <InlineError errorMessage={state.nameError} />
              )} */}
            </div>
          </div>
        </div>

        <Button
          buttonName="Save & Continue"
          type="Submit"
          onCliick={handleSubmit}
        />
      </form>
    </div>
  );
};
