import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { MobileNumber } from "./component/page/MobileNumber";
import { Otp } from "./component/page/Otp";
import { CompleteKyc } from "./component/page/CompleteKyc";
import { AssignLimit } from "./component/page/AssignLimit";
import { AgreeTermAndCondition } from "./component/page/AgreeTermAndCondition";
import { AddBankDetails } from "./component/page/AddBankDetails";
import { createBrowserHistory } from "history";
import axios from "axios";
import { SignInWithGmail } from "./component/page/SignInwithGmail";
import { useEffect } from "react";
import { SetWebView } from "./component/page/SetuWebView";

export const history = createBrowserHistory();

function App() {
  let token = localStorage.getItem("token");
  useEffect(() => {
    if (token !== null) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    }
  }, [token]);
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={MobileNumber} />
        <Route path="/verify-otp" component={Otp} />
        <Route path="/complete-your-kyc" component={CompleteKyc} />
        <Route path="/agree-term-condition" component={AgreeTermAndCondition} />
        <Route path="/assign-limit" component={AssignLimit} />
        <Route path="/add-bank-details" component={AddBankDetails} />
        <Route path="/sign-in" component={SignInWithGmail} />
      <Route path="/setu-web-view" component={SetWebView} />

      </Switch>
    </Router>
  );
}

export default App;
