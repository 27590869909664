/**
 * @about
 * This file conatins api end points
 */

import siteSetting from "../config/env/Index";

export default {
  USER_PHONE_VERIFY: siteSetting.api.url + "authentication/phone_no_verify/",
  USER_SEND_OTP: siteSetting.api.url + "send_otp_phone/",
  USER_OTP_VERIFY: siteSetting.api.url + "verify_otp_phone/",
  USER_KYC_URL: siteSetting.api.url + "webview_url/payme_ekyc/",
  USER_CREATE_ONBOARD_USER:
    siteSetting.api.url + "authentication/create_onboard_user/",
  USER_GET_ASSIGN_CREDIT_LIMIT: siteSetting.api.url + "credit_limit/",
  USER_GET_DOCS_STATUS: siteSetting.api.url + "get_document_status/",
  USER_REGISTER_WITH_GMAIL:
    siteSetting.api.url + "authentication/register_user/",
  USER_GET_DETAILS: siteSetting.api.url + "general_user_details/",
  USER_CHECKOUT: siteSetting.api.url + "merchant_transaction/",
  USER_GET_CHECKOUT_DETAILS: siteSetting.api.url + "merchant_transaction/checkout_details/",
  USER_GET_CIBIL_SCORE: siteSetting.api.url + "webview_url/cibil/",
  SETU_DECODE_TOKEN: siteSetting.api.url + "setu/token_decode/",
  SETU_TRANSACTION_INITIATE: siteSetting.api.url + "setu/transaction_initiate/",
  SETU_TRANSACTION_UPDATE: siteSetting.api.url + "setu/transaction_update/",
};
