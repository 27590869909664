/**
 * @about this file contains api
 */

import axios from "axios";
import Api from "./Api";

export {
  phoneVerify,
  sendOtp,
  otpVerify,
  getKyc,
  createOnboardUser,
  assignLimit,
  getDocs,
  getUser,
  checkout,
  userRgister,
  getCheckoutDetails,
  tokenDecode,
  getCibil,
  setuTokenDecode,
  setuTransactionInitiate,
  setuTransactionUpdate,
};

const headersApplicationJson = {
  "Content-Type": "application/json",
};

let token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Token ${token}`;
}


function tokenDecode(params) {
  return axios.post(`https://api.dev.merchant.paymeindia.in/api/v1/merchant/merchant_token_decode/`, params, {
    headers: headersApplicationJson,
  });
}

function phoneVerify(params) {
  return axios.post(Api.USER_PHONE_VERIFY, params, {
    headers: headersApplicationJson,
  });
}

function sendOtp(params) {
  return axios.post(Api.USER_SEND_OTP, params, {
    headers: headersApplicationJson,
  });
}

function otpVerify(params) {
  return axios.post(Api.USER_OTP_VERIFY, params, {
    headers: headersApplicationJson,
  });
}
function getKyc(params) {
  return axios.get(Api.USER_KYC_URL, params, {
    headers: headersApplicationJson,
  });
}

function createOnboardUser(params) {
  return axios.post(Api.USER_CREATE_ONBOARD_USER, params, {
    headers: headersApplicationJson,
  });
}

function assignLimit(params) {
  return axios.get(Api.USER_GET_ASSIGN_CREDIT_LIMIT, params, {
    headers: headersApplicationJson,
  });
}
// function assignLimitSetu(params) {
//   return axios.get(Api.USER_GET_ASSIGN_CREDIT_LIMIT, params, {
//     headers: {
//       'Authorization': `Token ${params}`,
//       'Accept' : 'application/json',
//       'Content-Type': 'application/json'
//   }
//   });
// }

function getDocs(params) {
  return axios.get(Api.USER_GET_DOCS_STATUS, params, {
    headers: headersApplicationJson,
  });
}

function userRgister(params) {
  return axios.post(Api.USER_REGISTER_WITH_GMAIL, params, {
    headers: headersApplicationJson,
  });
}

function getUser(params) {
  return axios.get(Api.USER_GET_DETAILS, params, {
    headers: headersApplicationJson,
  });
}

function checkout(params) {
  return axios.post(Api.USER_CHECKOUT, params, {
    headers: headersApplicationJson,
  });
}

function getCheckoutDetails(params) {
  return axios.get(Api.USER_GET_CHECKOUT_DETAILS, params, {
    headers: headersApplicationJson,
  });
}

function getCibil(params) {
  return axios.get(Api.USER_GET_CIBIL_SCORE, params, {
    headers: headersApplicationJson,
  });
}

function setuTokenDecode(params) {
  return axios.post(Api.SETU_DECODE_TOKEN, params, {
    headers: headersApplicationJson,
  });
}

function setuTransactionUpdate(params) {
  return axios.post(Api.SETU_TRANSACTION_UPDATE, params, {
    headers: headersApplicationJson,
  });
}

function setuTransactionInitiate(params) {
  return axios.post(Api.setuTransactionInitiate, params, {
    headers: headersApplicationJson,
  });
}
