import React from "react";
import { useHistory } from "react-router-dom";
import saltlogo from "../../assets/images/saltlogo.svg";

export const Header = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="logo" onClick={() => history.push('/')}>
        <img src={saltlogo} alt="Back button" />
      </div>
    </>
  );
};
