import React from "react";
import saltlogo from "../../assets/images/saltlogo.svg";

export const HeaderLogoLeft = () => {
  return (
    <>
      <div className="float-left ">
        <img src={saltlogo} alt="Back button" className='logo-left-img' />
      </div>
    </>
  );
};
